/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//estilo global de la importacion de letra de googlefonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300&display=swap');

//creando clases de letras globales
.titulo{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 22px !important;
    font-weight: bold !important;
}
.subtitulo{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 18px !important;
    font-weight: bold !important;
}
.normal{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
}

// !iportant se utiliza para que la clase sea aplicada sin importar clases globales 

//estilo global que utiliza el modal de categorias (en proceso de mejora)
.ion-modal {
    padding-left: 5%!important;
    padding-right: 5%!important;
    padding-top: 20%!important;
    padding-bottom: 40%!important;
}


ion-accordion-group{
  ion-accordion {
    ion-item[slot="header"] {
        font-style: italic;
            font-family: "Poppins", sans-serif;
            background: #1a1814;
            background-color: #1a1814;
            color: #1a1814;
        ion-icon[slot="end"] {
            font-style: italic;
            font-size: 12px;
            font-family: "Poppins", sans-serif;
            background: #1a1814;
            color: #fff;
        }
    }
  }
}

//para modal de mapa (en revision)
ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
  }
